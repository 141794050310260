.App {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.App-logo {
  height: 30vmin;
  width: 30vmin;
  pointer-events: none;
  color: white;
  filter: invert(1);
  position: sticky;
}

.App-logo2 {
  height: 15vmin;
  width: 15vmin;
  pointer-events: none;
  color: white;
  filter: invert(1);
  margin-bottom: 10px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: grey;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

.legal {
  text-align: left;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 60px;
  font-size: calc(6px + 1vmin);
  color: white;
  padding: 20px;
}

.about {
  /* margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px; */
margin:20px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

.updates {
  margin: 20px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: white;
  padding: 20px;
}

.first {
  text-indent: 50px;
}

.content {
  text-align: left;
  line-height: 25px;
  /* font-size: 16px; */
  font-size: calc(6px + 1vmin);
  text-align: justify;
  margin-bottom: 50px;
  padding: 10px;
}

.support {
  /* margin-top: 50px; */
  margin: 50px;
  /* margin-left: 50px; */
  min-height: 80vh;
  font-size: calc(7px + 1vmin);
  color: grey;
  text-align: left;
  padding: 20px;
}

h3 {
  color: white;
}
h4 {
  color: white;
}

.footer {
  color: grey;
  background-color: black;
  font-size: 12px;
  position: fixed;
  text-align: center;
  justify-content: center;
  align-items: center; 
  padding: .5rem;
  position: fixed;
  margin: 0 auto;
  bottom: 0;
  right: 0;
  left: 0;
}

.nav {
  font-size: calc(7px + 1vmin);
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: black;
  z-index: 1;
}

.link {
  margin: 20px;
  text-decoration: none;
  color: grey
}

.iconContainer {
  align-items: center;
  padding: 0;
  margin: auto;
}

.icon {
  font-size: 25px;
  color: grey;
  margin: 0 10px;
}
